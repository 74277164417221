import React from "react";

import {
	BorderOutlined,
	CaretRightOutlined,
	DisconnectOutlined,
	FilterOutlined,
	PictureOutlined,
	ProfileOutlined,
	ReadOutlined,
	SearchOutlined,
} from "@ant-design/icons";
import { Collapse } from "antd";

import * as boxTypeLib from "@lib/box/box-type";
import * as lensesLib from "@lib/lenses/lenses";
import * as boxLib from "@lib/box/box";
import { VisibilityStateMap } from "@lib/box/visibility-state";
import { BoxStyleMap } from '@lib/box/box-style';

import * as configurationLib from "@lib/configuration/configuration";

import { VisibilityStateSelector } from "../../../molecules/VisibilityStateSelector";
import { BookmarkMap } from "../CurrentIllustrationPage";
import LensPageList from "./LensPageList";
import ViewMenuItemHeader from "./ViewMenuItemHeader";
import { Legend } from "../../../molecules/Legend";
import BoxTreeView from "../../../molecules/BoxTreeView";
import BoxTypeTreeView from "../../../molecules/BoxTypeTreeView";
import { LensVisibilityList } from "../../../molecules/LensVisibilityList";
import { LensGroupVisibilityList } from "../../../molecules/LensGroupVisibilityList";
import LatchModeView from "@components/molecules/LatchModeView";
import { RenderFunctionLatchModeVisibilityMap } from "@lib/box/render-function";

const { Panel } = Collapse;

export type ViewMenuProps = {
	viewSidebarDisplay: string;
	viewSidebarWidthInPixels: number;
	componentHeightInPixels: number;
	isViewSidebarDisabled: boolean;

	currentVisibilityStateKey: string;
	visibilityStateMap: VisibilityStateMap | undefined;
	onVisibilityStateChange: (visibilityStateKey: string) => void;

	onBookmarkSelect: (boxKey: string, smartPageKey: string) => void;
	lensPageMap: BookmarkMap | undefined;

	boxStyles: BoxStyleMap | undefined;

	showStoryMenu: boolean;
	showLensesMenu: boolean;
	showSimpleLensesMenu: boolean;
	showLensPagesMenu: boolean;
	showLegendMenu: boolean;
	showTypesMenu: boolean;
	showSimpleTypesMenu: boolean;
	showBoxesMenu: boolean;
	showLatchModeMenu: boolean;
	onBoxVisibilityOrLayoutChange: (
		boxKey: string,
		isVisible: boolean,
		isInLayout: boolean
	) => void;
	onBoxVisibilityChange: (boxKey: string, isVisible: boolean) => void;
	boxes: boxLib.BoxMap | undefined;
	boxVisibilityMap: boxLib.BoxVisibilityMap;
	onBoxTypeBoxesVisibilityChange: (
		boxTypeKeyHierarchy: string[],
		areBoxesVisible: boolean,
		areBoxesInLayout: boolean
	) => void;
	onBoxTypeVisibilityChange: (
		boxTypeKeyHierarchy: string[],
		isVisible: boolean,
		includeAttributes: boolean
	) => void;
	onAttributeTypeVisibilityChange: (
		boxTypeKeyHierarchy: string[],
		attributeTypeKey: string,
		isVisible: boolean
	) => void;
	onRenderFunctionLatchModeVisibilityChange: (
		boxTypeKey: string,
		attributeTypeKey: string,
		renderFunctionKey: string,
		enabled: boolean
	) => void;

	boxTypeMap: boxTypeLib.BoxTypeMap | undefined;
	boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap;
	lenses: lensesLib.LensMap | undefined;
	lensGroups: lensesLib.LensGroupMap | undefined;
	onLensGroupVisibilityChange: (
		lensGroupKey: string,
		isVisible: boolean
	) => void;
	onLensVisibilityChange: (lensKey: string, isVisible: boolean) => void;
	lensVisibilityMap: lensesLib.LensVisibilityMap | undefined;
	lensGroupVisibilityMap: lensesLib.LensGroupVisibilityMap | undefined;

	simpleLenses: lensesLib.LensMap | undefined;
	simpleLensGroups: lensesLib.LensGroupMap | undefined;
	onSimpleLensGroupVisibilityChange: (
		lensGroupKey: string,
		isVisible: boolean
	) => void;
	onSimpleLensVisibilityChange: (lensKey: string, isVisible: boolean) => void;
	simpleLensVisibilityMap: lensesLib.LensVisibilityMap | undefined;
	simpleLensGroupVisibilityMap: lensesLib.LensGroupVisibilityMap | undefined;
};

const panelStyle = {
	backgroundColor: configurationLib.DEFAULT_UI_VIEW_SIDEBAR_BACKGROUND_COLOR,
	borderRadius: 0,
	border: 0,
	margin: 0,
	padding: 0,
};

const ViewMenu = (props: ViewMenuProps) => {
	return (
		<div
			style={{
				display: props.viewSidebarDisplay,
				position: "relative",
				float: "left",
				width: props.viewSidebarWidthInPixels,
				height: props.componentHeightInPixels,
				padding: 0,
				overflow: "hidden",
				pointerEvents: props.isViewSidebarDisabled ? "none" : "auto",
			}}
		>
			<div
				style={{
					height: "100%",
					width: "100%",
					overflow: "auto",
					backgroundColor:
						configurationLib.DEFAULT_UI_VIEW_SIDEBAR_BACKGROUND_COLOR,
				}}
			>
				<Collapse
					bordered={false}
					expandIcon={(panelProps) => (
						<CaretRightOutlined
							rotate={panelProps.isActive ? 90 : 0}
							style={{
								color:
									configurationLib.DEFAULT_UI_VIEW_SIDEBAR_TEXT_COLOR,
							}}
						/>
					)}
					style={{
						backgroundColor:
							configurationLib.DEFAULT_UI_VIEW_SIDEBAR_BACKGROUND_COLOR,
					}}
				>
					{props.showStoryMenu && props.visibilityStateMap && (
						<Panel
							key="1"
							header={
								<ViewMenuItemHeader
									icon={FilterOutlined}
									text={"Story"}
								/>
							}
							style={panelStyle}
						>
							<VisibilityStateSelector
								onVisibilityStateChange={
									props.onVisibilityStateChange
								}
								visibilityStateMap={props.visibilityStateMap}
								currentVisibilityStateKey={
									props.currentVisibilityStateKey
								}
							/>
						</Panel>
					)}
					{props.showSimpleLensesMenu && (
						<Panel
							key="2"
							header={
								<ViewMenuItemHeader
									icon={SearchOutlined}
									text={"Lenses"}
								/>
							}
							style={panelStyle}
						>
							{props.simpleLensGroups || props.simpleLenses ? (
								!props.simpleLensGroups ||
								Object.keys(props.simpleLensGroups).length <= 0 ? (
									<LensVisibilityList
										onLensVisibilityChange={
											props.onSimpleLensVisibilityChange
										}
										lensKeys={
											props.simpleLenses
												? Object.keys(props.simpleLenses)
												: []
										}
										lensMap={props.lenses}
										lensVisibilityMap={
											props.simpleLensVisibilityMap
										}
										textColor={
											configurationLib.DEFAULT_UI_VIEW_SIDEBAR_TEXT_COLOR
										}
									/>
								) : (
									<LensGroupVisibilityList
										onLensVisibilityChange={
											props.onSimpleLensVisibilityChange
										}
										onLensGroupVisibilityChange={
											props.onSimpleLensGroupVisibilityChange
										}
										lensMap={props.simpleLenses}
										lensVisibilityMap={
											props.simpleLensVisibilityMap
										}
										lensGroupMap={props.simpleLensGroups}
										lensGroupVisibilityMap={
											props.simpleLensGroupVisibilityMap
										}
										textColor={
											configurationLib.DEFAULT_UI_VIEW_SIDEBAR_TEXT_COLOR
										}
									/>
								)
							) : null}
						</Panel>
					)}
					{props.showLensesMenu && (
						<Panel
							key="3"
							header={
								<ViewMenuItemHeader
									icon={SearchOutlined}
									text={"Power Lenses"}
								/>
							}
							style={panelStyle}
						>
							{props.lensGroups || props.lenses ? (
								!props.lensGroups ||
								Object.keys(props.lensGroups).length <= 0 ? (
									<LensVisibilityList
										onLensVisibilityChange={
											props.onLensVisibilityChange
										}
										lensKeys={
											props.lenses
												? Object.keys(props.lenses)
												: []
										}
										lensMap={props.lenses}
										lensVisibilityMap={
											props.lensVisibilityMap
										}
										textColor={
											configurationLib.DEFAULT_UI_VIEW_SIDEBAR_TEXT_COLOR
										}
									/>
								) : (
									<LensGroupVisibilityList
										onLensVisibilityChange={
											props.onLensVisibilityChange
										}
										onLensGroupVisibilityChange={
											props.onLensGroupVisibilityChange
										}
										lensMap={props.lenses}
										lensVisibilityMap={
											props.lensVisibilityMap
										}
										lensGroupMap={props.lensGroups}
										lensGroupVisibilityMap={
											props.lensGroupVisibilityMap
										}
										textColor={
											configurationLib.DEFAULT_UI_VIEW_SIDEBAR_TEXT_COLOR
										}
									/>
								)
							) : null}
						</Panel>
					)}
					{props.showLensPagesMenu && (
						<Panel
							key="4"
							header={
								<ViewMenuItemHeader
									icon={ReadOutlined}
									text={"Lens Pages"}
								/>
							}
							style={panelStyle}
						>
							<LensPageList
								sidebarTextColor={
									configurationLib.DEFAULT_UI_VIEW_SIDEBAR_TEXT_COLOR
								}
								lensPageMap={props.lensPageMap}
								onBookmarkSelect={props.onBookmarkSelect}
							/>
						</Panel>
					)}
					{props.showLegendMenu && (
						<Panel
							key="5"
							header={
								<ViewMenuItemHeader
									icon={PictureOutlined}
									text={"Legend"}
								/>
							}
							style={panelStyle}
						>
							<Legend boxStyles={props.boxStyles} />
						</Panel>
					)}
					{props.showSimpleTypesMenu && (
						<Panel
							key="6"
							header={
								<ViewMenuItemHeader
									icon={ProfileOutlined}
									text={"Box Types"}
								/>
							}
							style={panelStyle}
						>
							<BoxTypeTreeView
								onBoxTypeBoxesVisibilityOrLayoutChange={
									props.onBoxTypeBoxesVisibilityChange
								}
								onBoxTypeVisibilityChange={
									props.onBoxTypeVisibilityChange
								}
								onAttributeTypeVisibilityChange={
									props.onAttributeTypeVisibilityChange
								}
								boxTypeMap={props.boxTypeMap}
								boxTypeVisibilityMap={
									props.boxTypeVisibilityMap
								}
								textColor={
									configurationLib.DEFAULT_UI_VIEW_SIDEBAR_TEXT_COLOR
								}
								showAttributes={false}
							/>
						</Panel>
					)}
					{props.showTypesMenu && (
						<Panel
							key="7"
							header={
								<ViewMenuItemHeader
									icon={ProfileOutlined}
									text={"Advanced Box Types"}
								/>
							}
							style={panelStyle}
						>
							<BoxTypeTreeView
								onBoxTypeBoxesVisibilityOrLayoutChange={
									props.onBoxTypeBoxesVisibilityChange
								}
								onBoxTypeVisibilityChange={
									props.onBoxTypeVisibilityChange
								}
								onAttributeTypeVisibilityChange={
									props.onAttributeTypeVisibilityChange
								}
								boxTypeMap={props.boxTypeMap}
								boxTypeVisibilityMap={
									props.boxTypeVisibilityMap
								}
								textColor={
									configurationLib.DEFAULT_UI_VIEW_SIDEBAR_TEXT_COLOR
								}
								showAttributes={true}
							/>
						</Panel>
					)}
					{props.showBoxesMenu && (
						<Panel
							key="8"
							header={
								<ViewMenuItemHeader
									icon={BorderOutlined}
									text={"Boxes"}
								/>
							}
							style={panelStyle}
						>
							{" "}
							<BoxTreeView
								onBoxVisibilityOrLayoutChange={
									props.onBoxVisibilityOrLayoutChange
								}
								onBoxVisibilityChange={
									props.onBoxVisibilityChange
								}
								boxes={props.boxes}
								boxVisibilityMap={props.boxVisibilityMap}
								textColor={
									configurationLib.DEFAULT_UI_VIEW_SIDEBAR_TEXT_COLOR
								}
								editVisibility={true}
							/>
						</Panel>
					)}
					{props.showLatchModeMenu && (
						<Panel
							key="8"
							header={
								<ViewMenuItemHeader
									icon={DisconnectOutlined}
									text={"Latch Mode"}
								/>
							}
							style={panelStyle}
						>
							{" "}
							<LatchModeView
								onLatchChange={props.onRenderFunctionLatchModeVisibilityChange}

								boxTypeMap={props.boxTypeMap}
								boxTypeVisibilityMap={
									props.boxTypeVisibilityMap
								}
								textColor={
									configurationLib.DEFAULT_UI_VIEW_SIDEBAR_TEXT_COLOR
								}
								showAttributes={true}
							/>
						</Panel>
					)}
				</Collapse>
			</div>
			{props.isViewSidebarDisabled ? (
				<div
					style={{
						display: "inline",
						position: "absolute",
						top: 0,
						left: 0,
						width: props.viewSidebarWidthInPixels,
						height: props.componentHeightInPixels,
						padding: 0,
						overflow: "hidden",
						backgroundColor: "rgba(0, 0, 0, 0.5)",
					}}
				/>
			) : null}
		</div>
	);
};

export default ViewMenu;
